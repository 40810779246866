import { rtdb } from '@/firebase';

const state = {
  rtdbActiveSubscriptions: [],
  rtdbActiveSubscriptionsLoaded: false,
};

const mutations = {
  SET_RTDB_SUBSRIPTIONS(state, payload) {
    state.rtdbActiveSubscriptions.push(payload);
  },
  // LOADERS
  RTDB_SUBSRIPTIONS_LOADED(state) {
    state.rtdbActiveSubscriptionsLoaded = true;
  },
};

const getters = {};

const actions = {
  async GET_RTDB_ACTIVE_SUBSCRIPTIONS({ commit, state }) {
    let getData = rtdb
      .ref('subscriptions/')
      .orderByChild('subscription')
      .equalTo(true);
    getData.on('value', function (snapshot) {
      snapshot.forEach(function (childSnapshot) {
        if (
          !state.rtdbActiveSubscriptions.some(
            (subscription) =>
              subscription.username === childSnapshot.val().username
          )
        ) {
          const data = {
            type: childSnapshot.val().type,
            username: childSnapshot.val().username,
            profilePhoto: childSnapshot.val().profilePhoto,
            status: childSnapshot.val().status,
            userId: childSnapshot.val().userId,
            live: childSnapshot.val().live,
            game: childSnapshot.val().game,
            subscription: childSnapshot.val().subscription,
            viewers: childSnapshot.val().viewers,
            displayName: childSnapshot.val().displayName,
          };
          commit('SET_RTDB_SUBSRIPTIONS', data);
        }
      });
    });
    commit('RTDB_SUBSRIPTIONS_LOADED');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
