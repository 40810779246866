import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        purple: '#6d1bff',
        orange: '#FFA62E',
        pink: '#FD4D5C',
        grey: '#71797E',
        green: '#00BFA5',
      },
    },
  },
});
export default vuetify;
