import { db } from '@/firebase';
const state = {
  products: [],
  productsPrices: [],
  subscriptions: [],
  adminSubscriptions: [],
  subscriptionInvoices: [],
  profileSubscriptions: [],
  profileSubscription: {},
  accountSubscription: null,
  clientSubscriptions: [],

  // LOADERS
  subscriptionLoaded: false,
  accountSubscriptionLoaded: false,
};

const mutations = {
  SET_ADMIN_SUBSCRIPTIONS(state, payload) {
    state.adminSubscriptions.push(payload);
  },
  SET_SUBSCRIPTIONS(state, payload) {
    state.subscriptions.push(payload);
  },
  SET_SUBSCRIPTIONS_INVOICES(state, payload) {
    state.subscriptionInvoices.push(payload);
  },
  SET_PROFILE_SUBSCRIPTIONS(state, payload) {
    state.profileSubscriptions.push(payload);
  },
  SET_PROFILE_SUBSCRIPTION(state, payload) {
    state.profileSubscription = payload;
    state.profileSubscription.active = true;
  },
  SET_ACCOUNT_SUBSCRIPTION(state, payload) {
    state.accountSubscription = payload.data;
    if (payload) {
      state.accountSubscription.username = payload.username;
    }
  },
  SET_ACCOUNT_COLLAB_SUBSCRIPTION(state) {
    state.accountSubscription = true;
  },
  SUBSCRIPTION_LOADED(state) {
    state.subscriptionLoaded = true;
  },
  ACCOUNT_SUBSCRIPTION_LOADED(state) {
    state.accountSubscriptionLoaded = true;
  },

  SET_PRODUCTS(state, payload) {
    if (payload.metadata.type) {
      state.products.push(payload.metadata.type);
    }
    if (payload.metadata.visibility === 'true') {
      // if (payload.metadata.type === "subscription") {
      //   if (payload.metadata.currency === "usd") {
      //     state.subscriptionUSDProducts.push(payload);
      //   } else if (payload.metadata.currency === "gbp") {
      //     state.subscriptionGPBProducts.push(payload);
      //   } else if (payload.metadata.currency === "ron") {
      //     state.subscriptionRONProducts.push(payload);
      //   }
      // } else
      if (payload.metadata.type === 'credits') {
        if (payload.metadata.currency === 'ron') {
          state.paymentRONCredits.push(payload);
        } else if (payload.metadata.currency === 'usd') {
          state.paymentUSDCredits.push(payload);
        }
      }
      //   else if (payload.metadata.type === "vbucks") {
      //   if (payload.metadata.currency === "ron") {
      //     state.paymentRONVbucks.push(payload);
      //   } else if (payload.metadata.currency === "usd") {
      //     state.paymentUSDVbucks.push(payload);
      //   }
      // } else if (payload.metadata.type === "cheaperSubs") {
      //   if (payload.metadata.currency === "ron") {
      //     state.paymentRONCheaperSubs.push(payload);
      //   } else if (payload.metadata.currency === "usd") {
      //     state.paymentUSDCheaperSubs.push(payload);
      //   }
      // }
    }
  },
  CLEAR_ACCOUNT_SUBSCRIPTION(state) {
    state.accountSubscription = null;
  },
  CLEAR_PRODUCTS(state) {
    state.products = [];
    state.paymentRONCredits = [];
    state.paymentUSDCredits = [];
    state.paymentRONVbucks = [];
    state.paymentUSDVbucks = [];
    state.paymentUSDCheaperSubs = [];
    state.paymentRONCheaperSubs = [];
  },
};

const getters = {};

const actions = {
  async GET_ADMIN_SUBSCRIPTIONS({ commit, state }) {
    state.clientSubscriptions = [];

    const dataBase = await db.collection('clients');
    const dbResults = await dataBase.get();
    const subscriptions = dbResults;
    if (subscriptions) {
      subscriptions.forEach(async (doc) => {
        const parentEmail = doc.data().email; // Fetch the parent email

        if (
          !state.adminSubscriptions.some(
            (subscription) => subscription.email === doc.data().email
          )
        ) {
          const dataBaseSubscriptions = await db
            .collection('clients')
            .doc(doc.id)
            .collection('subscriptions');

          const dbResultsClientsSubscriptions =
            await dataBaseSubscriptions.get();
          dbResultsClientsSubscriptions.forEach(async (result) => {
            if (
              !state.clientSubscriptions.some(
                (subscription) => subscription.id === result.id
              )
            ) {
              let subscription = {
                id: result.id,
                email: parentEmail,
                status: result.data().status,
                amount_paid_ron: result.data().metadata.amount_paid_ron,
                trial_start: result.data().trial_start,
                trial_end: result.data().trial_end,
                current_period_start: result.data().current_period_start,
                current_period_end: result.data().current_period_end,
              };
              state.clientSubscriptions.push(subscription);

              commit('SET_ADMIN_SUBSCRIPTIONS', subscription);
            }
          });
        }
      });
    } else {
      state.profileSubscription = false;
    }
  },

  async GET_PROFILE_SUBSCRIPTION({ commit, state }, userId) {
    const dataBase = await db
      .collection('clients')
      .doc(userId)
      .collection('subscriptions');
    const dbResults = await dataBase.get();
    const profileSubscriptions = dbResults;
    profileSubscriptions.forEach(async (doc) => {
      if (!doc.empty) {
        if (
          !state.profileSubscriptions.some(
            (subscription) => subscription.id === doc.id
          )
        ) {
          commit('SET_PROFILE_SUBSCRIPTIONS', doc.id);
          if (doc.data().status === 'active') {
            state.profileSubscription = doc.data();
            const dataBaseInvoices = await db
              .collection('clients')
              .doc(userId)
              .collection('subscriptions')
              .doc(doc.id)
              .collection('invoices');
            const dbResultsInvoices = await dataBaseInvoices.get();
            const subscriptionInvoices = dbResultsInvoices;
            subscriptionInvoices.forEach(async (doc) => {
              if (
                !state.subscriptionInvoices.some(
                  (invoice) => invoice.id === doc.data().id
                ) &&
                doc.data().id
              ) {
                let amount = doc.data().total;
                let currency = doc.data().currency;
                if (currency === 'usd') {
                  amount = amount / 100 + '.00';
                }
                const data = {
                  id: doc.data().id,
                  currency: doc.data().currency,
                  status: doc.data().status,
                  date: doc.data().period_start,
                  amount: amount,
                  type: doc.data().object,
                  userId: doc.data().userId,
                };
                commit('SET_SUBSCRIPTIONS_INVOICES', data);
              }
            });
            commit('SET_PROFILE_SUBSCRIPTION', doc.data());
          } else {
            commit('SET_PROFILE_SUBSCRIPTION', {
              active: false,
            });
          }
        } else {
          commit('SET_PROFILE_SUBSCRIPTION', {
            active: false,
          });
        }
      }
    });

    commit('SUBSCRIPTION_LOADED');
  },
  async GET_ACCOUNT_SUBSCRIPTION({ commit, state }, username) {
    state.accountSubscription = null;
    if (username) {
      try {
        const querySnapshot = await db
          .collection('clients')
          .where('username', '==', username)
          .get();

        if (!querySnapshot.empty) {
          const clientDoc = querySnapshot.docs[0]; // Assuming there's only one client with this username
          const subscriptionsCollectionRef =
            clientDoc.ref.collection('subscriptions');

          const subscriptionsSnapshot = await subscriptionsCollectionRef.get();

          subscriptionsSnapshot.forEach((subscriptionDoc) => {
            const subscriptionData = subscriptionDoc.data();
            if (
              subscriptionData.status === 'active' ||
              subscriptionData.status === 'trialing'
            ) {
              commit('SET_ACCOUNT_SUBSCRIPTION', {
                data: subscriptionData,
                username: username,
              });
            }
          });
        } else {
          commit('SET_ACCOUNT_SUBSCRIPTION', false);
        }
      } catch (error) {
        console.error('Error retrieving account subscription:', error);
        // Optionally, handle the error accordingly
      }
    } else {
      // Optionally, handle the case where username is empty or undefined
      commit('SET_ACCOUNT_SUBSCRIPTION', false);
    }

    commit('ACCOUNT_SUBSCRIPTION_LOADED');
  },
  async GET_PRODUCTS({ commit, state }) {
    // Clear state
    commit('CLEAR_PRODUCTS');

    try {
      // Fetch active products
      const productsSnapshot = await db
        .collection('products')
        .where('active', '==', true)
        .get();

      const products = [];
      const priceFetchPromises = [];

      productsSnapshot.forEach((doc) => {
        const productData = doc.data();

        if (
          productData &&
          productData.name &&
          !products.some((product) => product.name === productData.name)
        ) {
          const fetchPrices = async () => {
            const pricesSnapshot = await db
              .collection('products')
              .doc(doc.id)
              .collection('prices')
              .get();
            const pricesList = [];

            pricesSnapshot.forEach((priceDoc) => {
              const priceData = priceDoc.data();
              if (
                priceData &&
                priceData.active &&
                priceData.description &&
                !state.productsPrices.some(
                  (item) => item.product === priceData.product
                )
              ) {
                pricesList.push({ id: priceDoc.id, ...priceData });
              }
            });

            return {
              metadata: productData.metadata,
              prices: pricesList,
              name: productData.name,
            };
          };

          priceFetchPromises.push(fetchPrices());
        }
      });

      const productsWithPrices = await Promise.all(priceFetchPromises);

      productsWithPrices.forEach((product) => {
        commit('SET_PRODUCTS', product);
      });
    } catch (error) {
      console.error('Error fetching products:', error);
      // Handle the error appropriately
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
