import { db, rtdb } from '@/firebase';
import router from '../../../router';
import _ from 'lodash';

const state = {
  // FIRESTORE SETTERS
  accountsVisited: [],
  accountsTourneysVisited: [],
  account: {},
  accountUserId: null,
  accountUsername: null,
  accountMainGame: null,
  accountLastActiveTourneyMonth: null,
  accountLastActiveTourneyDate: null,
  accountMonthlyTourneys: [],
  accounts: [],
  accountExp: {},
  accountExpTweets: [],
  accountExpTourneys: [],
  accountTourneyApplications: [],
  accountClips: [],
  accountTourneysCooldown: [],
  accountTourneys: [],
  accountMerch: [],
  accountRanks: [],
  loadedTourneyTotals: [],
  accountAllGiveaways: [],
  accountActiveGiveaways: [],
  accountGiveawaysCooldown: null,
  accountFortniteBuildsLevel: null,
  profileAccountExp: null,
  // FIRESTORE LOADERS
  accountTourneysLoaded: false,
  accountMonthlyTourneysLoaded: false,
  accountTourneyLoaded: false,
  accountLoaded: false,
  accountsLoaded: false,
  tourneyBettingsTotalLoaded: false,
  accountExpLoaded: false,
  accountRanksLoaded: false,
  accountGiveawaysLoaded: false,

  // RTDB SETTERS
  rtdbTourneyParticipants: [],
  // RTDB LOADERS
  rtdbTourneyLoaded: false,
  rtdbTourneyBriefLoaded: false,
  rtdbMonthlyTourneyBriefLoaded: false,

  // NOT USED
  accountAllTourneys: [],
};

const mutations = {
  // Set mutations
  SET_ACCOUNTS(state, payload) {
    state.accounts.push(payload);
  },
  SET_ACCOUNT(state, payload) {
    const accountIndex = state.accountsVisited.findIndex((item) => {
      return item.username === payload.user;
    });
    if (accountIndex !== -1) {
      state.account = state.accountsVisited[accountIndex];
      state.accountUserId = state.accountsVisited[accountIndex].userId;
      state.accountUsername = state.accountsVisited[accountIndex].username;
      state.accountMainGame = state.accountsVisited[accountIndex].mainGame;
      state.accountLastActiveTourneyMonth =
        state.accountsVisited[accountIndex].lastActiveTourneyMonth;
      state.accountLastActiveTourneyDate =
        state.accountsVisited[accountIndex].lastActiveTourneyDate;
    } else {
      state.accountsVisited.push(payload.account.data());
      state.account = payload.account.data();
      state.accountUserId = payload.account.data().userId;
      state.accountUsername = payload.account.data().username;
      state.accountMainGame = payload.account.data().mainGame;
      state.accountLastActiveTourneyMonth =
        payload.account.data().lastActiveTourneyMonth;
      state.accountLastActiveTourneyDate =
        payload.account.data().lastActiveTourneyDate;
    }
  },
  SET_ACCOUNT_INITIALS(state) {
    let accountInitials =
      state.account.firstName.match(/(\b\S)?/g).join('') +
      state.account.lastName.match(/(\b\S)?/g).join('');
    state.account['accountInitials'] = accountInitials;
  },
  SET_ACCOUNT_TOURNEY(state, payload) {
    state.accountTourney = payload;
  },
  SET_ACCOUNT_TOURNEYS_PROFILE(state, payload) {
    if (payload && payload.clips) {
      state.accountClips = payload.clips;
    }
    if (payload.cooldown) {
      state.accountTourneysCooldown = payload.cooldown;
    }
  },
  SET_ACCOUNT_RANKS(state, payload) {
    let data = payload.data();
    state.accountRanks = data;
    if (data && data.fortniteBuildsLevel) {
      state.accountFortniteBuildsLevel = data.fortniteBuildsLevel;
    }
  },
  RESET_ACCOUNT_TOURNEYS(state) {
    state.accountTourneys = [];
    state.accountExpTourneys = [];
    state.accountTourneyApplications = [];
  },
  RESET_ACCOUNT_MONTHLY_TOURNEYS(state) {
    state.accountMonthlyTourneys = [];
  },
  SET_RTDB_TOURNEY_BRIEF(state, payload) {
    let index = state.accountTourneys.findIndex((tourney) => {
      return tourney.docId === payload.val().docId;
    });

    if (index !== -1) {
      // Ensure that 'participants' is set on the tourney if it doesn't exist
      if (!state.accountTourneys[index].participants) {
        state.accountTourneys[index].participants = 0;
      }

      state.accountTourneys[index].participants =
        payload.val().participants || 0;
      state.accountTourneys[index].totalAmount = payload.val().totalAmount || 0;
    } else {
      // If no matching tourney is found, you can optionally log a message or handle the case
      console.error('Tourney not found with docId:', payload.val().docId);
    }
  },
  SET_RTDB_MONTHLY_TOURNEY_BRIEF(state, payload) {
    let index = state.accountMonthlyTourneys.findIndex((tourney) => {
      return tourney.docId === payload.val().docId;
    });

    if (index !== -1) {
      // Ensure that 'participants' is set on the tourney if it doesn't exist
      if (!state.accountMonthlyTourneys[index].participants) {
        state.accountMonthlyTourneys[index].participants = 0;
      }

      state.accountMonthlyTourneys[index].participants =
        payload.val().participants || 0;
      state.accountMonthlyTourneys[index].totalAmount =
        payload.val().totalAmount || 0;
    } else {
      // If no matching tourney is found, you can optionally log a message or handle the case
      console.error('Tourney not found with docId:', payload.val().docId);
    }
  },
  SET_RTDB_TOURNEY(state, payload) {
    state.rtdbTourneyParticipants = payload.val().participants;
  },
  ADD_LOADED_TOURNEY_TOTAL(state, docId) {
    // Check if the docId is not already in the array
    if (!state.loadedTourneyTotals.includes(docId)) {
      // Add the docId to the array
      state.loadedTourneyTotals.push(docId);
    }
  },
  SET_ACCOUNT_VISITED_TOURNEYS(state, payload) {
    const accountTourneysIndex = state.accountsTourneysVisited.findIndex(
      (item) => {
        return item.userId === payload.userId;
      }
    );
    if (accountTourneysIndex !== -1) {
      state.accountTourneys =
        state.accountsTourneysVisited[accountTourneysIndex];
    } else {
      return;
    }
  },
  SET_ACCOUNT_TOURNEYS(state, payload) {
    let containsCoins = false;
    let containsPrizes = false;
    if (payload.winnersCoins && payload.winnersCoins.length) {
      containsCoins = payload.winnersCoins.some(
        (item) => item.username === state.accountUsername
      );
    }
    if (payload.winnersPrizes && payload.winnersPrizes.length) {
      containsPrizes = payload.winnersPrizes.some(
        (item) => item.username === state.accountUsername
      );
    }

    if (containsCoins || containsPrizes) {
      payload.claimRewards = true;
    } else {
      payload.claimRewards = false;
    }
    // if (payload.participants.length) {
    //   payload.participants.forEach((participant) => {
    //     if (participant.username === state.accountUsername) {
    //       payload.participant = true;
    //     } else {
    //       payload.participant = false;
    //     }
    //   });
    // } else {
    //   payload.participant = false;
    // }
    // if (payload.coinsParticipants.length) {
    //   payload.coinsParticipants.forEach((participant) => {
    //     if (participant.username === state.accountUsername) {
    //       payload.coinsParticipant = true;
    //     } else {
    //       payload.coinsParticipant = false;
    //     }
    //   });
    // } else {
    //   payload.coinsParticipant = false;
    // }

    state.accountTourneys.push(payload);
    state.accountTourneys = _.orderBy(
      state.accountTourneys,
      ['active'],
      ['desc']
    );
    console.log(state.accountTourneys);
  },
  SET_ACCOUNT_MONTHLY_TOURNEYS(state, payload) {
    let containsCoins = false;
    let containsPrizes = false;
    if (payload.winnersCoins && payload.winnersCoins.length) {
      containsCoins = payload.winnersCoins.some(
        (item) => item.username === state.accountUsername
      );
    }
    if (payload.winnersPrizes && payload.winnersPrizes.length) {
      containsPrizes = payload.winnersPrizes.some(
        (item) => item.username === state.accountUsername
      );
    }

    if (containsCoins || containsPrizes) {
      payload.claimRewards = true;
    } else {
      payload.claimRewards = false;
    }
    state.accountMonthlyTourneys.push(payload);
  },

  SET_ACCOUNT_GIVEAWAYS(state, payload) {
    let containsPrizes = false;
    if (payload.participants && state.profileUsername) {
      const participantStatus = payload.participants.some(
        (item) => item.username === state.profileUsername
      );
      if (participantStatus) {
        payload.participate = true;
      } else {
        payload.participate = false;
      }
    } else {
      payload.participate = false;
    }

    if (payload.winnersPrizes && payload.winnersPrizes.length) {
      containsPrizes = payload.winnersPrizes.some(
        (item) => item.username === state.profileUsername
      );
    } else if (payload.winners && payload.winners.length) {
      containsPrizes = payload.winners.some(
        (item) => item.username === state.profileUsername
      );
    }
    if (containsPrizes) {
      payload.claimRewards = true;
    } else {
      payload.claimRewards = false;
    }
    payload.totalAmount = state.totalMoneyAmount;
    state.accountAllGiveaways.push(payload);
  },
  SET_ACCOUNT_MERCH(state, payload) {
    state.accountMerch = payload.data();
  },
  SET_ACCOUNT_EXP_TWEETS(state, payload) {
    state.accountExpTweets.push(payload);
  },
  SET_ACCOUNT_EXP(state, payload) {
    state.accountExp = payload;
    state.accountExpList = payload.expList;
  },
  SET_PROFILE_EXP(state, payload) {
    if (payload.expList && payload.expList.length) {
      const index = payload.expList.findIndex((item) => {
        return item.username === payload.profileUsername;
      });
      if (index !== -1) {
        state.profileAccountExp = payload.expList[index];
      } else {
        let updateUser = {
          username: payload.profileUsername,
          twitterUsername: state.profileTwitterUsername,
          userExp: 0,
        };
        state.profileAccountExp = updateUser;
      }
    } else {
      let updateUser = {
        username: payload.profileUsername,
        twitterUsername: state.profileTwitterUsername,
        userExp: 0,
      };
      state.profileAccountExp = updateUser;
    }
  },
  SET_ACCOUNT_GIVEAWAY(state, payload) {
    if (payload.cooldown) {
      state.accountGiveawaysCooldown = payload.cooldown;
    }
  },
  // CRUD DB SETTERS
  UPDATE_ACCOUNT_LAST_ACTIVE_TOURNEY_MONTH(state, payload) {
    state.accountLastActiveTourneyMonth = payload;
  },
  UPDATE_ACCOUNT_LAST_ACTIVE_TOURNEY_DATE(state, payload) {
    state.accountLastActiveTourneyDate = payload;
  },

  // FIRESTORE LOADERS

  ACCOUNTS_LOADED(state) {
    state.accountsLoaded = true;
  },
  ACCOUNT_LOADED(state) {
    state.accountLoaded = true;
  },
  ACCOUNT_TOURNEYS_LOADED(state) {
    state.accountTourneysLoaded = true;
  },
  ACCOUNT_MONTHLY_TOURNEYS_LOADED(state) {
    state.accountMonthlyTourneysLoaded = true;
  },
  ACCOUNT_EXP_LOADED(state) {
    state.accountExpLoaded = true;
  },
  ACCOUNT_RANKS_LOADED(state) {
    state.accountRanksLoaded = true;
  },
  ACCOUNT_TOURNEY_LOADED(state) {
    state.accountTourneyLoaded = true;
  },
  GIVEAWAYS_LOADED(state) {
    state.accountGiveawaysLoaded = true;
  },

  // RTDB LOADERS
  RTDB_TOURNEY_LOADED(state) {
    state.rtdbTourneyLoaded = true;
  },
  RTDB_TOURNEY_BRIEF_LOADED(state) {
    state.rtdbTourneyBriefLoaded = true;
  },
  RTDB_MONTHLY_TOURNEY_BRIEF_LOADED(state) {
    state.rtdbMonthlyTourneyBriefLoaded = true;
  },

  // NOT USED
  SET_ACCOUNT_ALL_TOURNEYS(state, payload) {
    state.accountAllTourneys.push(payload);
  },
};

const getters = {};

const actions = {
  async GET_ACCOUNTS({ commit, state }) {
    const dataBase = await db.collection('accounts');
    const dbResults = await dataBase.get();
    const accounts = dbResults;

    accounts.forEach((doc) => {
      if (
        !state.accounts.some(
          (account) => account.userId === doc.data().userId
        ) &&
        doc.data().userId
      ) {
        const data = {
          userId: doc.data().userId,
          username: doc.data().username,
          firstName: doc.data().firstName,
          lastName: doc.data().lastName,
          profilePhoto: doc.data().profilePhoto,
          created_at: doc.data().created_at,
          email: doc.data().email,
          followers: doc.data().followers,
          mode: doc.data().mode,
          rank: doc.data().rank,
          referral: doc.data().referral,
          streamer: doc.data().streamer,
        };
        commit('SET_ACCOUNTS', data);
      }
    });
    commit('ACCOUNTS_LOADED');
  },
  async GET_ACCOUNT({ commit }, user) {
    const dataBase = await db
      .collection('accounts')
      .where('username', '==', user);
    const dbResults = await dataBase.get();
    const account = dbResults.docs[0];
    if (account == undefined) {
      // need to set routeName === 'tourney' check where this is called on tourney view
      router.push({ name: 'home' });
    } else {
      // if (account.data().streamer && state.user) {
      //   let getAccountExp = await rtdb.ref(
      //     "/exp/" + fb.auth().currentUser.uid
      //   );
      //   getAccountExp.once("value", function (snapshot) {
      //     let data = snapshot;
      //     let username = account.data().username;
      //     if (!data.val()[username]) {
      //       let updateRanks = {
      //         [username + "Exp"]: 0,
      //         [username]: username,
      //       };
      //       rtdb.ref("exp/" + fb.auth().currentUser.uid).update(updateRanks);
      //     }
      //   });
      // }
      commit('SET_ACCOUNT', { account, user });
      commit('SET_ACCOUNT_INITIALS');
      // let getRankings = await rtdb.ref("/exp/" + account.data().userId);
      // getRankings.once("value", function (snap) {
      //   commit("SET_RTDB_ACCOUNT_EXP", snap);
      // });
      commit('ACCOUNT_LOADED');
    }
  },

  async GET_ACCOUNT_RANKS({ commit }, userId) {
    const dataBase = await db.collection('ranks').doc(userId);
    const dbResults = await dataBase.get();
    commit('SET_ACCOUNT_RANKS', dbResults);
    commit('ACCOUNT_RANKS_LOADED');
  },
  async GET_ACCOUNT_TOURNEYS({ dispatch, commit, state }, username) {
    try {
      const dataBase = db
        .collection('tourneysFn')
        .where('username', '==', username) // Check for specific username
        .where('visibility', '==', true) // Check for specific username
        .where('archive', '==', false) // Ensure the tourney is not archived
        .orderBy('created_at', 'desc') // Order by the deadline field in descending order
        .limit(3); // Limit results to 3

      // Fetch the results from the database
      const dbResults = await dataBase.get();

      if (!dbResults.empty) {
        // Create an array of promises for each tourney processing
        const tourneyPromises = dbResults.docs.map(async (doc) => {
          const accountTourney = doc.data(); // Get the tourney data

          // Ensure tourney is not already in state
          if (
            !state.accountTourneys.some((tourney) => tourney.docId === doc.id)
          ) {
            console.log(doc.id);
            processSnap(accountTourney); // Process each document directly

            // Commit the tourney to the store
            commit('SET_ACCOUNT_TOURNEYS', {
              ...accountTourney,
              docId: doc.id,
            });

            // Fetch related bets for each tourney (await dispatch)
            await dispatch('GET_RTDB_TOURNEY_BRIEF', accountTourney);
          }
        });

        // Wait for all the tourney promises (including dispatch) to finish
        await Promise.all(tourneyPromises);

        // Indicate that account tourneys have been loaded
        commit('ACCOUNT_TOURNEYS_LOADED');
      } else {
        console.log('No account tourneys found for user:', username);
      }
    } catch (error) {
      console.error('Error fetching account tourneys:', error);
      // Optionally, handle the error further (e.g., show a message to the user)
    }

    // Helper functions
    function processSnap(snap) {
      setTweetStep(snap);
      setParticipationStatus(snap);
      // setBuyInStatus(snap);
    }

    function setTweetStep(snap) {
      if (!state.profileTwitterStatus) {
        snap.tweetStep = 1;
      } else if (
        snap.tweetLikes &&
        snap.tweetLikes.includes(state.profileTwitterUsername)
      ) {
        snap.tweetStep = 3;
      } else if (
        snap.pendingList &&
        snap.pendingList.includes(state.profileTwitterUsername)
      ) {
        snap.tweetStep = 2;
        snap.stepCompleted = false;
        snap.stepStatus = 'Pending';
      } else {
        snap.tweetStep = 2;
        snap.stepCompleted = false;
        snap.stepStatus = 'Complete Step';
      }
    }

    function setParticipationStatus(snap) {
      snap.allowed = false;
      if (
        snap.allowedRanks &&
        (snap.allowedRanks.includes(state.profileFortniteBuildsLevel) ||
          snap.allowedRanks[0] === 'Everyone')
      ) {
        snap.allowed = true;
      }
    }

    // function setBuyInStatus(snap, profileUsername) {
    //   if (!snap.buyInCoins) {
    //     const hasValue = snap.participants.some(
    //       (obj) => obj.username === profileUsername
    //     );
    //     if (!hasValue && snap.buyInCoinsAmount > state.currentProfileRanking) {
    //       snap.disabled = true;
    //       snap.reason = 'notEnough';
    //     } else {
    //       snap.disabled = false;
    //       snap.reason = 'enough';
    //     }
    //   } else {
    //     if (snap.buyInCoinsAmount > state.rtdbProfileCredits) {
    //       snap.disabled = true;
    //       snap.reason = 'notEnough';
    //     } else {
    //       snap.disabled = false;
    //       snap.reason = 'enough';
    //     }
    //   }
    // }
  },

  async GET_ACCOUNT_MONTHLY_TOURNEYS({ dispatch, commit, state }, newDate) {
    const currentDate = new Date();
    let yearMonth = currentDate.toISOString().slice(0, 7);

    if (yearMonth !== newDate) {
      yearMonth = newDate;
    }
    try {
      const collectionPath = `tourneys${state.account.gameShortcut}`;
      const dataBase = await db
        .collection(collectionPath)
        .where('username', '==', state.account.username)
        .where('deadline', '>=', `${yearMonth}-01`)
        .where('deadline', '<', `${yearMonth}-32`)
        .where('active', '==', false);
      const dbResults = await dataBase.get();

      if (!dbResults.empty) {
        // Create an array of promises for each tourney processing
        const tourneyPromises = dbResults.docs.map(async (doc) => {
          const accountMonthlyTourney = doc.data();

          if (
            !state.accountMonthlyTourneys.some(
              (tourney) => tourney.docId === doc.id
            )
          ) {
            commit('SET_ACCOUNT_MONTHLY_TOURNEYS', {
              ...accountMonthlyTourney,
              id: doc.id,
            });

            // Fetch related bets for each tourney (await dispatch)
            await dispatch(
              'GET_RTDB_MONTHLY_TOURNEY_BRIEF',
              accountMonthlyTourney
            );
          }
        });

        // Wait for all the tourney promises (including dispatch) to finish
        await Promise.all(tourneyPromises);
        commit('ACCOUNT_MONTHLY_TOURNEYS_LOADED');
      }
    } catch (error) {
      console.error('Error fetching account tourneys:', error);
    }
  },
  async GET_RTDB_TOURNEY({ commit }, tourneyDocId) {
    // If not loaded, fetch the data
    let getData = await rtdb.ref('/tourneys/' + tourneyDocId);
    getData.once('value', function (snapshot) {
      if (snapshot.exists()) {
        commit('SET_RTDB_TOURNEY', snapshot);
      }
    });
    commit('RTDB_TOURNEY_LOADED');
  },
  async GET_RTDB_TOURNEY_BRIEF({ commit }, tourney) {
    return new Promise((resolve, reject) => {
      // Fetch data from the real-time database
      const getData = rtdb.ref('/tourneysBrief/' + tourney.docId);

      getData.once(
        'value',
        function (snapshot) {
          if (snapshot.exists()) {
            // Commit the data if it exists
            commit('SET_RTDB_TOURNEY_BRIEF', snapshot);
          }

          // Commit mutation indicating that the RTDB tournament data has been loaded
          commit('RTDB_TOURNEY_BRIEF_LOADED');

          // Resolve the promise once all actions are completed
          resolve();
        },
        reject
      ); // Reject the promise if there's an error during the fetch
    });
  },
  async GET_RTDB_MONTHLY_TOURNEY_BRIEF({ commit }, tourney) {
    return new Promise((resolve, reject) => {
      // Fetch data from the real-time database
      const getData = rtdb.ref('/tourneysBrief/' + tourney.docId);

      getData.once(
        'value',
        function (snapshot) {
          if (snapshot.exists()) {
            // Commit the data if it exists
            commit('SET_RTDB_MONTHLY_TOURNEY_BRIEF', snapshot);
          }

          // Commit mutation indicating that the RTDB tournament data has been loaded
          commit('RTDB_MONTHLY_TOURNEY_BRIEF_LOADED');

          // Resolve the promise once all actions are completed
          resolve();
        },
        reject
      ); // Reject the promise if there's an error during the fetch
    });
  },
  async GET_ACCOUNT_TOURNEY({ commit }, tourney) {
    let dataBase = await db.collection('tourneys').doc(tourney.docId);
    const dbResults = await dataBase.get();
    let results = dbResults.data();
    if (results) {
      commit('SET_ACCOUNT_TOURNEY', results);
    }
    commit('ACCOUNT_TOURNEY_LOADED');
  },

  async GET_ACCOUNT_GIVEAWAYS({ commit, state }, userId) {
    state.accountAllGiveaways = [];
    state.accountActiveGiveaways = [];
    let dataBase = db.collection('giveaways').doc(userId);
    const dbResults = await dataBase.get();
    let results = dbResults.data();
    if (results) {
      commit('SET_ACCOUNT_GIVEAWAY', results);
      if (results.list) {
        results.list.forEach(function (snap) {
          if (
            !state.accountAllGiveaways.some(
              (giveaway) => giveaway.docId === snap.docId
            )
          ) {
            if (!state.profileTwitterStatus) {
              snap.tweetStep = 1;
            } else if (
              snap.pendingList &&
              snap.pendingList.includes(state.profileTwitterUsername)
            ) {
              snap.tweetStep = 2;
              snap.stepCompleted = false;
              snap.stepStatus = 'Pending';
            } else {
              snap.tweetStep = 2;
              snap.stepCompleted = false;
              snap.stepStatus = 'Complete Step';
            }
            if (
              snap.tweetLikes &&
              snap.tweetLikes.includes(state.profileTwitterUsername)
            ) {
              snap.tweetStep = 3;
              snap.stepCompleted = false;
            }
            let data = {};
            if (state.profileAccountExp) {
              if (snap.expEnterAmount > state.profileAccountExp.userExp) {
                data = {
                  ...snap,
                  disabled: true,
                  reason: 'notEnough',
                };
              } else {
                data = {
                  ...snap,
                  reason: 'enough',
                  disabled: false,
                };
              }
            }
            commit('SET_ACCOUNT_GIVEAWAYS', data);
          }
        });
      }
    }
    commit('GIVEAWAYS_LOADED');
  },
  async GET_ACCOUNT_MERCH({ commit, state }) {
    state.accountMerch = [];
    const dataBase = await db.collection('merch').doc(state.account.userId);
    const dbResults = await dataBase.get();
    const merch = dbResults;
    if (merch) {
      commit('SET_ACCOUNT_MERCH', merch);
    }
  },
  async GET_ACCOUNT_EXP(
    { commit, state },
    userId,
    profileTwitterUsername,
    profileUsername
  ) {
    state.accountExp = {};
    state.accountExpTweets = [];
    const dataBase = await db.collection('exp').doc(userId);
    const dbResults = await dataBase.get();
    const accountExp = dbResults.data();
    let completedSteps = 1;
    let pendingSteps = 1;
    if (accountExp && accountExp.tweets) {
      accountExp.tweets.forEach((doc) => {
        if (!state.accountExpTweets.some((tweet) => tweet.id === doc.id)) {
          if (
            doc.completedList &&
            doc.completedList.includes(profileTwitterUsername)
          ) {
            accountExp.step = completedSteps++;
            accountExp.currentStep = accountExp.step + 1;
            doc.stepCompleted = true;
            doc.stepStatus = 'Completed';
          } else if (
            (doc.pendingList &&
              doc.pendingList.includes(profileTwitterUsername)) ||
            (doc.requests && doc.requests.includes(profileTwitterUsername))
          ) {
            {
              accountExp.pendingCount = pendingSteps++;
              doc.stepCompleted = true;
              doc.currentStep = accountExp.step + 1;
              doc.stepStatus = 'Pending';
              doc.btnStatus = 'Pending';
            }
            accountExp.currentStep = accountExp.step + 1;
            doc.stepCompleted = true;
            doc.stepStatus = 'Pending';
          } else if (
            doc.pendingList &&
            !doc.pendingList.includes(profileTwitterUsername)
          ) {
            doc.stepCompleted = false;
            accountExp.currentStep = accountExp.step + 1;
            doc.stepStatus = 'Complete';
            doc.btnStatus = 'Complete';
          } else {
            doc.stepCompleted = false;
            doc.stepStatus = 'Complete';
          }
          doc.link =
            'https://www.twitter.com/' +
            state.account.twitterUsername +
            '/status/' +
            doc.id;
          if (accountExp.step === undefined) {
            accountExp.step = 0;
            accountExp.currentStep = 1;
          }
          commit('SET_ACCOUNT_EXP_TWEETS', doc);
          commit('SET_ACCOUNT_EXP', accountExp);
        }
      });
    }
    commit('SET_PROFILE_EXP', { accountExp, profileUsername });
    commit('ACCOUNT_EXP_LOADED');
  },

  // ACTIONS
  async ENTER_ACCOUNT_TOURNEY({ rootState, state }, data) {
    try {
      // Get the current user information from rootState
      let profileUserId = rootState.UserStore.profileUserId;
      let profileTourneysEntered = [
        ...rootState.UserStore.profileTourneysEntered,
      ]; // Clone the array
      // Ensure state.rtdbTourneyParticipants is updated and contains the participants
      const currentParticipants = state.rtdbTourneyParticipants;
      if (currentParticipants.length === 0) {
        console.error(
          'No participants found. The state may not be updated properly.'
        );
        return;
      }

      // Push the new participant to the participants list
      currentParticipants.push(data.enterRequest);

      // Update RTDB - /tourneys
      const rtdbRef = rtdb.ref(`/tourneys/${data.tourneyDocId}`);
      await rtdbRef.update({
        participants: currentParticipants,
        userId: data.enterRequest.userId,
      });

      // Update RTDB - /tourneysBrief
      const rtdbRef1 = rtdb.ref(`/tourneysBrief/${data.tourneyDocId}`);
      await rtdbRef1.update({
        participants: currentParticipants.length, // Update with the new length
        userId: data.enterRequest.userId,
      });

      // Update the profile tourneys entered in Firestore
      profileTourneysEntered.push(data.tourneyDocId);
      rootState.UserStore.profileTourneysEntered = profileTourneysEntered;
      await db.collection('accounts').doc(profileUserId).update({
        tourneysEntered: profileTourneysEntered,
        userId: profileUserId,
      });
    } catch (error) {
      console.error('Error entering the tourney:', error);
      // Optionally handle the error (e.g., show a message to the user)
    }
  },

  async UPDATE_ACCOUNT(account) {
    await db.collection('accounts').doc(account.userId).update(account);
  },

  // UNUSED
  async GET_ACCOUNT_ALL_TOURNEYS({ commit }, username) {
    try {
      // Query Firestore for tourneys with the specified username
      const dataBase = await db
        .collection('tourneysFn')
        .where('username', '==', username);

      const dbResults = await dataBase.get();

      // Check if there are any documents
      if (!dbResults.empty) {
        // Loop through each document in the Firestore result
        dbResults.forEach(async (doc) => {
          const accountTourneys = doc.data(); // Get the document data
          const docId = doc.id; // Get the document ID

          // Check if the participants field exists and is an array
          if (
            accountTourneys.participants &&
            accountTourneys.participants.length
          ) {
            // Update the Realtime Database with the participants array
            const rtdbRef = rtdb.ref(`/tourneys/${docId}`);
            await rtdbRef.update({
              participants: accountTourneys.participants,
              userId: accountTourneys.userId,
              docId: accountTourneys.docId,
              username: accountTourneys.username,
            });
            const rtdbRef1 = rtdb.ref(`/tourneysBrief/${docId}`);
            await rtdbRef1.update({
              participants: accountTourneys.participants.length,
              totalCoinsAmount: 0,
              totalMoneyAmount: 0,
              userId: accountTourneys.userId,
              docId: accountTourneys.docId,
              username: accountTourneys.username,
            });
          } else {
            console.log(`No participants found for docId: ${docId}`);
          }

          commit('SET_ACCOUNT_ALL_TOURNEYS', accountTourneys);
        });
      } else {
        console.log('No tourneys found for this user.');
      }
    } catch (error) {
      console.error('Error fetching or updating tourneys:', error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
