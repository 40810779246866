import { db, rtdb } from '@/firebase';
import _ from 'lodash';

const state = {
  accountWonTourneys: [],
  allMoneyTourneys: [],
  activeTourneys: [],
  latestTourneys: [],
  tourney: {},

  // LOADERS
  tourneyLoaded: false,
  activeTourneysLoaded: false,
  latestTourneysLoaded: false,
  rtdbActiveTourneyBriefLoaded: false,
  rtdbLatestTourneyBriefLoaded: false,
};

const mutations = {
  // SETTERS
  SET_ACCOUNT_WON_TOURNEYS(state, payload) {
    state.accountWonTourneys.push(payload.data());
  },
  SET_TOURNEY(state, payload) {
    state.tourney = payload;
  },
  TOURNEY_LOADED(state) {
    state.tourneyLoaded = true;
  },
  SET_ACTIVE_TOURNEYS(state, payload) {
    state.activeTourneys.push(payload);

    state.activeTourneys = _.orderBy(
      state.activeTourneys,
      ['buyInCoinsAmount', 'active', 'participants.length', 'created_at'],
      ['asc', 'desc', 'desc', 'desc']
    );
  },

  SET_LATEST_TOURNEYS(state, payload) {
    state.latestTourneys.push(payload);

    state.latestTourneys = _.orderBy(
      state.latestTourneys,
      ['buyInCoinsAmount', 'active', 'participants.length', 'created_at'],
      ['asc', 'desc', 'desc', 'desc']
    );
  },
  SET_RTDB_ACTIVE_TOURNEY_BRIEF(state, payload) {
    let index = state.activeTourneys.findIndex((tourney) => {
      return tourney.docId === payload.val().docId;
    });

    if (index !== -1) {
      // Ensure that 'participants' is set on the tourney if it doesn't exist
      if (!state.activeTourneys[index].participants) {
        state.activeTourneys[index].participants = 0;
      }

      state.activeTourneys[index].participants =
        payload.val().participants || 0;
      state.activeTourneys[index].totalAmount = payload.val().totalAmount || 0;
    } else {
      // If no matching tourney is found, you can optionally log a message or handle the case
      console.error('Tourney not found with docId:', payload.val().docId);
    }
  },
  SET_RTDB_LATEST_TOURNEY_BRIEF(state, payload) {
    let index = state.latestTourneys.findIndex((tourney) => {
      return tourney.docId === payload.val().docId;
    });

    if (index !== -1) {
      // Ensure that 'participants' is set on the tourney if it doesn't exist
      if (!state.latestTourneys[index].participants) {
        state.latestTourneys[index].participants = 0;
      }

      state.latestTourneys[index].participants =
        payload.val().participants || 0;
      state.latestTourneys[index].totalAmount = payload.val().totalAmount || 0;
    } else {
      // If no matching tourney is found, you can optionally log a message or handle the case
      console.error('Tourney not found with docId:', payload.val().docId);
    }
  },
  SET_ALL_MONEY_TOURNEYS(state, payload) {
    const isDuplicate = state.allMoneyTourneys.some(
      (tourney) => tourney.docId === payload.data.docId
    );

    if (isDuplicate && payload.data.list) {
      payload.data.list.forEach((item) => {
        var hasValue = item.participants.some(function (obj) {
          return obj.username === payload.profileUsername;
        });
        if (!item.buyInCoins) {
          if (!hasValue) {
            if (item.buyInCoinsAmount > state.currentProfileRanking) {
              item = {
                ...item,
                disabled: true,
                reason: 'notEnough',
              };
            } else {
              item = {
                ...item,
                reason: 'enough',
                disabled: false,
              };
            }
          } else if (hasValue) {
            item = {
              ...item,
              reason: 'entered',
              disabled: true,
            };
          }
        } else if (item.buyInCoins) {
          if (item.buyInCoinsAmount > state.rtdbProfileCredits) {
            item = {
              ...item,
              disabled: true,
              reason: 'notEnough',
            };
          } else {
            item = {
              ...item,
              reason: 'enough',
              disabled: false,
            };
          }
        }
        let containsCoins = false;
        let containsPrizes = false;
        if (item.winnersCoins && item.winnersCoins.length) {
          containsCoins = item.winnersCoins.some(
            (item) => item.username === payload.profileUsername
          );
        }
        if (item.winnersPrizes && item.winnersPrizes.length) {
          containsPrizes = item.winnersPrizes.some(
            (item) => item.username === payload.profileUsername
          );
        }

        if (containsCoins || containsPrizes) {
          item.claimRewards = true;
        } else {
          item.claimRewards = false;
        }
        let coinsParticipants = [];

        if (item.participants && item.participants.length) {
          item.participants.forEach((participant) => {
            if (participant.username === payload.profileUsername) {
              item.participant = true;
            }
            if (participant.coins) {
              coinsParticipants.push(participant);
            }
          });
        } else if (!item.participants.length) {
          item.participate = false;
        }
        if (item.coinsParticipants && item.coinsParticipants.length) {
          item.coinsParticipants.forEach((participant) => {
            if (participant.username === payload.profileUsername) {
              item.coinsParticipant = true;
            } else {
              item.coinsParticipant = false;
            }
          });
        } else {
          item.coinsParticipant = false;
        }
        item.coinsParticipants = coinsParticipants;

        let newItem = {
          ...item,
          username: payload.data.username,
          profilePhoto: payload.data.profilePhoto,
          discordLink: payload.data.discordLink,
          liveLink: payload.data.liveLink,
        };

        state.allMoneyTourneys.push(newItem);
      });
    } else {
      state.allMoneyTourneys.push(payload.data);
    }
    state.allMoneyTourneys = _.orderBy(
      state.allMoneyTourneys,
      ['buyInCoinsAmount', 'active', 'participants.length', 'created_at'],
      ['asc', 'desc', 'desc', 'desc']
    );
  },
  // ACTIONS
  ARCHIVE_TOURNEY(state, payload) {
    const index = state.accountTourneys.findIndex((tourney) => {
      return tourney.docId === payload.docId;
    });
    state.accountTourneys[index].active = false;
  },
  // LOADERS
  RTDB_ACTIVE_TOURNEY_BRIEF_LOADED(state) {
    state.rtdbActiveTourneyBriefLoaded = true;
  },
  RTDB_LATEST_TOURNEY_BRIEF_LOADED(state) {
    state.rtdbLatestTourneyBriefLoaded = true;
  },
  ACTIVE_TOURNEYS_LOADED(state) {
    state.activeTourneysLoaded = true;
  },
  LATEST_TOURNEYS_LOADED(state) {
    state.latestTourneysLoaded = true;
  },
};

const getters = {};

const actions = {
  async GET_ACTIVE_TOURNEYS({ dispatch, commit, state }) {
    try {
      const dataBase = await db
        .collection('tourneysFn')
        .where('active', '==', true);
      const dbResults = await dataBase.get();

      if (!dbResults.empty) {
        // Create an array of promises for each tourney processing
        const tourneyPromises = dbResults.docs.map(async (doc) => {
          const tourney = doc.data();

          if (
            !state.activeTourneys.some((tourney) => tourney.docId === doc.id)
          ) {
            commit('SET_ACTIVE_TOURNEYS', doc.data());
            // Fetch related bets for each tourney (await dispatch)
            await dispatch('GET_RTDB_ACTIVE_TOURNEY_BRIEF', tourney);
          }
        });

        // Wait for all the tourney promises (including dispatch) to finish
        await Promise.all(tourneyPromises);
        commit('ACTIVE_TOURNEYS_LOADED');
      }
    } catch (error) {
      console.error('Error fetching account tourneys:', error);
    }
  },
  async GET_RTDB_ACTIVE_TOURNEY_BRIEF({ commit }, tourney) {
    return new Promise((resolve, reject) => {
      // Fetch data from the real-time database
      const getData = rtdb.ref('/tourneysBrief/' + tourney.docId);

      getData.once(
        'value',
        function (snapshot) {
          if (snapshot.exists()) {
            // Commit the data if it exists
            commit('SET_RTDB_ACTIVE_TOURNEY_BRIEF', snapshot);
          }

          // Commit mutation indicating that the RTDB tournament data has been loaded
          commit('RTDB_ACTIVE_TOURNEY_BRIEF_LOADED');

          // Resolve the promise once all actions are completed
          resolve();
        },
        reject
      ); // Reject the promise if there's an error during the fetch
    });
  },
  async GET_LATEST_TOURNEYS({ dispatch, commit, state }) {
    try {
      const dataBase = await db
        .collection('tourneysFn')
        .where('archive', '==', false)
        .orderBy('deadline', 'desc')
        .limit(8);
      const dbResults = await dataBase.get();

      if (!dbResults.empty) {
        // Create an array of promises for each tourney processing
        const tourneyPromises = dbResults.docs.map(async (doc) => {
          const tourney = doc.data();

          if (
            !state.latestTourneys.some((tourney) => tourney.docId === doc.id)
          ) {
            commit('SET_LATEST_TOURNEYS', doc.data());
            // Fetch related bets for each tourney (await dispatch)
            await dispatch('GET_RTDB_LATEST_TOURNEY_BRIEF', tourney);
          }
        });

        // Wait for all the tourney promises (including dispatch) to finish
        await Promise.all(tourneyPromises);
        commit('LATEST_TOURNEYS_LOADED');
      }
    } catch (error) {
      console.error('Error fetching account tourneys:', error);
    }
  },
  async GET_RTDB_LATEST_TOURNEY_BRIEF({ commit }, tourney) {
    return new Promise((resolve, reject) => {
      // Fetch data from the real-time database
      const getData = rtdb.ref('/tourneysBrief/' + tourney.docId);

      getData.once(
        'value',
        function (snapshot) {
          if (snapshot.exists()) {
            // Commit the data if it exists
            commit('SET_RTDB_LATEST_TOURNEY_BRIEF', snapshot);
          }

          // Commit mutation indicating that the RTDB tournament data has been loaded
          commit('RTDB_LATEST_TOURNEY_BRIEF_LOADED');

          // Resolve the promise once all actions are completed
          resolve();
        },
        reject
      ); // Reject the promise if there's an error during the fetch
    });
  },
  async GET_MONEY_TOURNEYS({ commit, state }, profileUsername) {
    const dataBase = await db.collection('tourneysFn');
    const dbResults = await dataBase.get();
    const tourneys = dbResults;
    tourneys.forEach((doc) => {
      if (
        !state.allMoneyTourneys.some(
          (tourney) => tourney.id === doc.data().docId
        )
      ) {
        if (doc.data().list) {
          const list = doc.data().list.filter((item) => item.visibility);
          const data = {
            ...doc.data(),
            list: list,
          };

          commit('SET_ALL_MONEY_TOURNEYS', { data, profileUsername });
        } else {
          const data = doc.data();
          commit('SET_ALL_MONEY_TOURNEYS', { data, profileUsername });
        }
      }
    });
  },
  async GET_TOURNEY({ commit }, docId) {
    const dataBase = await db.collection('tourneys').doc(docId);
    const dbResults = await dataBase.get();
    const tourney = dbResults.data();
    commit('SET_TOURNEY', tourney);
    commit('TOURNEY_LOADED');
  },
  async GET_ACCOUNT_WON_TOURNEYS({ commit, state }, user) {
    state.accountWonTourneys = [];
    let dataBase = await db
      .collection('tourneys')
      .where('usernames', 'array-contains', user)
      .orderBy('deadline', 'desc')
      .limit(6);
    const dbResults = await dataBase.get();
    dbResults.forEach((doc) => {
      if (
        !state.accountWonTourneys.some((tourney) => tourney.docId === doc.docId)
      ) {
        commit('SET_ACCOUNT_WON_TOURNEYS', doc);
      }
    });
  },
  // UNUSED
  // UPDATE_ACTIVE_TOURNEYS(state, payload) {
  //   var hasValue = payload.data.participants.some(function (obj) {
  //     return obj.username === payload.data.profileUsername;
  //   });
  //   if (!payload.data.buyInCoins) {
  //     if (!hasValue) {
  //       if (payload.data.buyInCoinsAmount > state.currentProfileRanking) {
  //         payload.data = {
  //           ...payload.data,
  //           disabled: true,
  //           reason: 'notEnough',
  //         };
  //       } else {
  //         payload.data = {
  //           ...payload.data,
  //           reason: 'enough',
  //           disabled: false,
  //         };
  //       }
  //     } else if (hasValue) {
  //       payload.data = {
  //         ...payload.data,
  //         reason: 'entered',
  //         disabled: true,
  //       };
  //     }
  //   } else if (payload.data.buyInCoins) {
  //     if (payload.data.buyInCoinsAmount > state.rtdbProfileCredits) {
  //       payload.data = {
  //         ...payload.data,
  //         disabled: true,
  //         reason: 'notEnough',
  //       };
  //     } else {
  //       payload.data = {
  //         ...payload.data,
  //         reason: 'enough',
  //         disabled: false,
  //       };
  //     }
  //   }
  //   let containsCoins = false;
  //   let containsPrizes = false;
  //   if (
  //     payload.data &&
  //     payload.data.winnersCoins &&
  //     payload.data.winnersCoins.length
  //   ) {
  //     containsCoins = payload.data.winnersCoins.some(
  //       (item) => item.username === payload.profileUsername
  //     );
  //   }
  //   if (
  //     payload.data &&
  //     payload.data.winnersPrizes &&
  //     payload.data.winnersPrizes.length
  //   ) {
  //     containsPrizes = payload.data.winnersPrizes.some(
  //       (item) => item.username === payload.profileUsername
  //     );
  //   }

  //   if (containsCoins || containsPrizes) {
  //     payload.data.claimRewards = true;
  //   } else {
  //     payload.data.claimRewards = false;
  //   }
  //   let coinsParticipants = [];

  //   if (payload.data.participants && payload.data.participants.length) {
  //     payload.data.participants.forEach((participant) => {
  //       if (participant.username === payload.profileUsername) {
  //         payload.data.participant = true;
  //       }
  //       if (participant.coins) {
  //         coinsParticipants.push(participant);
  //       }
  //     });
  //   } else if (!payload.data.participants.length) {
  //     payload.data.participate = false;
  //   }
  //   if (
  //     payload.data.coinsParticipants &&
  //     payload.data.coinsParticipants.length
  //   ) {
  //     payload.data.coinsParticipants.forEach((participant) => {
  //       if (participant.username === payload.profileUsername) {
  //         payload.data.coinsParticipant = true;
  //       } else {
  //         payload.data.coinsParticipant = false;
  //       }
  //     });
  //   } else {
  //     payload.data.coinsParticipant = false;
  //   }
  //   payload.data.coinsParticipants = coinsParticipants;

  //   let newItem = {
  //     ...payload.data,
  //     username: payload.data.username,
  //     profilePhoto: payload.data.profilePhoto,
  //     discordLink: payload.data.discordLink,
  //     liveLink: payload.data.liveLink,
  //   };

  //   state.activeTourneys.push(newItem);

  //   state.activeTourneys = _.orderBy(
  //     state.activeTourneys,
  //     ['buyInCoinsAmount', 'active', 'participants.length', 'created_at'],
  //     ['asc', 'desc', 'desc', 'desc']
  //   );
  // },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
