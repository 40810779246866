import { db } from '@/firebase';

const state = {
  ranksApplications: [],
  ranks: [],
};

const mutations = {
  // SETTERS
  SET_FORTNITE_RANKS_APPLICATIONS(state, payload) {
    state.ranksApplications.push(payload);
  },

  SET_FORTNITE_RANKS(state, payload) {
    state.allRanks.push(payload);
    if (payload.fortniteBuildsLevel === 'Gold III') {
      state.rankingsSweatI.push(payload);
    } else if (payload.fortniteBuildsLevel === 'Gold II') {
      state.rankingsSweatII.push(payload);
    } else if (payload.fortniteBuildsLevel === 'Gold I') {
      state.rankingsSweatIII.push(payload);
    } else if (payload.fortniteBuildsLevel === 'Bronze III') {
      state.rankingsCasualI.push(payload);
    } else if (payload.fortniteBuildsLevel === 'Bronze II') {
      state.rankingsCasualII.push(payload);
    } else if (payload.fortniteBuildsLevel === 'Bronze I') {
      state.rankingsCasualIII.push(payload);
    }
  },
  SET_RANKS(state, payload) {
    state.ranks.push(payload);
  },
};

const getters = {};

const actions = {
  async GET_FORTNITE_RANKS_APPLICATIONS({ commit, state }) {
    const dataBase = await db
      .collection('ranksFn')
      .where('applicationStatus', '==', 'submitted');
    const dbResults = await dataBase.get();
    const rankings = dbResults;
    rankings.forEach((doc) => {
      if (
        !state.ranksApplications.some(
          (rank) => rank.userId === doc.data().userId
        )
      ) {
        const data = {
          applicationStatus: doc.data().applicationStatus,
          userId: doc.data().userId,
          discordUsername: doc.data().discordUsername,
          fortniteUsername: doc.data().fortniteUsername,
          username: doc.data().username,
          fortniteBuildsLevel: doc.data().fortniteBuildsLevel,
        };
        commit('SET_FORTNITE_RANKS_APPLICATIONS', data);
      }
    });
  },

  // NOT IN USE
  async GET_RANKS({ commit, state }) {
    const dataBase = await db.collection('ranks');
    const dbResults = await dataBase.get();
    const ranks = dbResults;
    ranks.forEach((doc) => {
      if (!state.ranks.some((rank) => rank.userId === doc.data().userId)) {
        commit('SET_RANKS', doc.data());
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
