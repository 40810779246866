import { rtdb } from '@/firebase';

const state = {
  rtdbSelectedCoinsWinners: [],
  rtdbSelectedPrizeWinners: [],
  rtdbAllBettings: [],
  selectedRtdbTourney: {},
  filteredBettings: [],
  filteredBettingsValue: 0,
  filteredBoosts: [],
  filteredBoostsValue: 0,
  coinsParticipant: null,
  candidateParticipant: null,
  rtdbCoinsParticipants: [],
  tourneyBettingsLoaded: false,
  bettingsSelectedBoosts: '' || 'goldCoinsBoosts',
  bettingsTab: 0,
};

const mutations = {
  SET_RTDB_TOURNEY(state, payload) {
    let profileRank = '';
    if (payload.profileFortniteBuildsLevel) {
      if (payload.profileFortniteBuildsLevel.includes('Diamond')) {
        profileRank = 'diamondCoinsParticipants';
      } else if (payload.profileFortniteBuildsLevel.includes('Gold')) {
        profileRank = 'goldCoinsParticipants';
      } else if (payload.profileFortniteBuildsLevel.includes('Silver')) {
        profileRank = 'silverCoinsParticipants';
      } else if (payload.profileFortniteBuildsLevel.includes('Bronze')) {
        profileRank = 'bronzeCoinsParticipants';
      }
    }

    state.rtdbAllBettings = payload.data.val();
    state.rtdbCoinsParticipants = [
      ...(state.rtdbAllBettings.diamondCoinsParticipants || []),
      ...(state.rtdbAllBettings.goldCoinsParticipants || []),
      ...(state.rtdbAllBettings.silverCoinsParticipants || []),
      ...(state.rtdbAllBettings.bronzeCoinsParticipants || []),
    ];
    state.rtdbCoinsBoosts = [
      ...(state.rtdbAllBettings.diamondCoinsBoosts || []),
      ...(state.rtdbAllBettings.goldCoinsBoosts || []),
      ...(state.rtdbAllBettings.silverCoinsBoosts || []),
      ...(state.rtdbAllBettings.bronzeCoinsBoosts || []),
    ];
    if (
      state.rtdbAllBettings.candidates &&
      state.rtdbAllBettings.candidates.length
    ) {
      const candidateParticipantHolder = state.rtdbAllBettings.candidates.some(
        (item) => item.username === payload.profileUsername && item.status
      );
      if (candidateParticipantHolder) {
        state.candidateParticipant = true;
      } else {
        state.candidateParticipant = false;
      }
    }
    if (
      state.rtdbAllBettings[profileRank] &&
      state.rtdbAllBettings[profileRank].length
    ) {
      const coinsParticipantHolder = state.rtdbAllBettings[profileRank].some(
        (item) => item.username === payload.profileUsername && item.status
      );
      if (coinsParticipantHolder) {
        state.coinsParticipant = true;
      } else {
        state.coinsParticipant = false;
      }
    }

    if (payload.tourney) {
      state.selectedRtdbTourney = payload.tourney;
    }
  },

  // UPDATES
  UPDATE_BETTINGS_RANKS(state, payload) {
    state.bettingsSelectedBoosts = payload.bettingsSelectedBoosts;
    state.bettingsTab = payload.bettingsTab;
  },
  UPDATE_RTDB_RANK_BETTINGS(state) {
    state.filteredBettings = [];
    state.filteredBettingsValue = 0;
    state.filteredBoosts = [];
    state.filteredBoostsValue = 0;

    if (
      (state.rtdbAllBettings[state.bettingsSelectedBoosts] &&
        state.rtdbAllBettings[state.bettingsSelectedBoosts].length) ||
      state.bettingsTab === 4
    ) {
      let filteredBoosts;
      if (state.bettingsTab < 4) {
        filteredBoosts = state.rtdbAllBettings[state.bettingsSelectedBoosts];
      } else if (state.bettingsTab === 4) {
        filteredBoosts = [
          ...state.rtdbAllBettings['diamondCoinsBoosts'],
          ...state.rtdbAllBettings['goldCoinsBoosts'],
          ...state.rtdbAllBettings['silverCoinsBoosts'],
          ...state.rtdbAllBettings['bronzeCoinsBoosts'],
        ];
      }
      state.filteredBoosts = filteredBoosts;
      state.filteredBoostsValue = filteredBoosts.length * 20;
    }

    if (
      state.rtdbAllBettings.sponsors &&
      state.rtdbAllBettings.sponsors.length
    ) {
      let filteredBettings;
      let filteredCoinsParticipants;
      if (state.bettingsTab === 0) {
        filteredBettings = state.rtdbAllBettings.sponsors.filter(
          (sponsor) => sponsor.status && sponsor.gameLevel.includes('Diamond')
        );
        filteredCoinsParticipants = Array.isArray(
          state.rtdbAllBettings.diamondCoinsParticipants
        )
          ? state.rtdbAllBettings.diamondCoinsParticipants.filter(
              (sponsor) => sponsor.status
            )
          : [];
      } else if (state.bettingsTab === 1) {
        filteredBettings = state.rtdbAllBettings.sponsors.filter(
          (sponsor) => sponsor.status && sponsor.gameLevel.includes('Gold')
        );
        filteredCoinsParticipants = Array.isArray(
          state.rtdbAllBettings.silverCoinsParticipants
        )
          ? state.rtdbAllBettings.silverCoinsParticipants.filter(
              (sponsor) => sponsor.status
            )
          : [];
      } else if (state.bettingsTab === 2) {
        filteredBettings = state.rtdbAllBettings.sponsors.filter(
          (sponsor) => sponsor.status && sponsor.gameLevel.includes('Silver')
        );
        filteredCoinsParticipants = Array.isArray(
          state.rtdbAllBettings.silverCoinsParticipants
        )
          ? state.rtdbAllBettings.silverCoinsParticipants.filter(
              (sponsor) => sponsor.status
            )
          : [];
      } else if (state.bettingsTab === 3) {
        filteredBettings = state.rtdbAllBettings.sponsors.filter(
          (sponsor) => sponsor.status && sponsor.gameLevel.includes('Bronze')
        );
        filteredCoinsParticipants = Array.isArray(
          state.rtdbAllBettings.bronzeCoinsParticipants
        )
          ? state.rtdbAllBettings.bronzeCoinsParticipants.filter(
              (sponsor) => sponsor.status
            )
          : [];
      } else if (state.bettingsTab === 4) {
        let allCoinsParticipants = [
          ...(state.rtdbAllBettings.diamondCoinsParticipants || []),
          ...(state.rtdbAllBettings.goldCoinsParticipants || []),
          ...(state.rtdbAllBettings.silverCoinsParticipants || []),
          ...(state.rtdbAllBettings.bronzeCoinsParticipants || []),
        ];
        filteredBettings = state.rtdbAllBettings.sponsors.filter(
          (sponsor) => sponsor.status
        );

        filteredCoinsParticipants = allCoinsParticipants.filter(
          (sponsor) => sponsor.status
        );
      }
      state.filteredBettings = [
        ...filteredBettings,
        ...filteredCoinsParticipants,
      ];
      state.filteredBettingsValue = state.filteredBettings.length * 20;
    }
  },
  // LOADERS
  RTDB_TOURNEY_LOADED(state) {
    state.tourneyBettingsLoaded = true;
  },
};

const getters = {};

const actions = {
  async GET_RTDB_TOURNEY(
    { commit, state },
    tourney,
    profileFortniteBuildsLevel
  ) {
    state.rtdbSelectedCoinsWinners = [];
    state.rtdbSelectedPrizeWinners = [];
    let getData = await rtdb.ref('/tourneys/' + tourney.docId);
    getData.on('value', function (snapshot) {
      let data = snapshot;
      commit('SET_RTDB_TOURNEY', { data, tourney, profileFortniteBuildsLevel });
      commit('UPDATE_RTDB_RANK_BETTINGS');
    });
    commit('RTDB_TOURNEY_LOADED');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
