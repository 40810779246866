const state = {};

const mutations = {
  // SET_PROFILE_EXP(state, payload) {
  //   state.profileTotalExp = 0;
  //   state.profileExp = payload.val();
  //   state.profileExpList = payload.val().expList;
  //   state.profileExpList.forEach((item) => {
  //     state.profileTotalExp = state.profileTotalExp + item.totalExp;
  //   });
  //   state.profileLevel = Math.trunc(state.profileTotalExp / 100);
  // },
  // UPDATE_PROFILE_EXP_AMOUNT(state, payload) {
  //   state.currentProfileRanking = state.currentProfileRanking - payload;
  // },
  // SET_PROFILE_EXP_LIST(state, payload) {
  //   state.challengeStepsTime = null;
  //   state.challengeTime = null;
  //   state.stepLoading = false;
  //   state.challengeShowDaily = false;
  //   if (state.profileExpList.length) {
  //     state.challengeCurrentIndex = state.profileExpList.findIndex(
  //       (account) => {
  //         return account.streamer === payload;
  //       }
  //     );
  //     if (state.challengeCurrentIndex !== -1) {
  //       state.currentExpList =
  //         state.profileExpList[state.challengeCurrentIndex];
  //       state.challengeStepsCompleted = state.currentExpList.currentStep;
  //       let today = moment();
  //       let todayFormat = moment(today).format("YY/MM/DD");
  //       if (todayFormat >= state.currentExpList.cooldown) {
  //         if (state.challengeStepsCompleted === 1) {
  //           state.challengeStepsTime = moment((1000 * 60) / 2);
  //           state.challengeTime = state.challengeStepsTime.format("mm:ss");
  //         } else if (state.challengeStepsCompleted === 2) {
  //           state.challengeStepsTime = moment(1000 * 60 * 2);
  //           state.challengeTime = state.challengeStepsTime.format("mm:ss");
  //         } else if (state.challengeStepsCompleted === 3) {
  //           state.challengeStepsTime = moment(1000 * 60 * 5);
  //           state.challengeTime = state.challengeStepsTime.format("mm:ss");
  //         }
  //         state.challengeShowDaily = true;
  //       }
  //     } else if (
  //       state.challengeCurrentIndex === -1 &&
  //       state.accountSubscription
  //     ) {
  //       let today = moment();
  //       let todayFormat = moment(today).format("YY/MM/DD");
  //       let updateExpList = {
  //         totalExp: 1500,
  //         cooldown: todayFormat,
  //         currentStep: 1,
  //         streamer: payload,
  //       };
  //       state.profileExpList.push(updateExpList);
  //       let updateProfile = {
  //         expList: state.profileExpList,
  //         [payload]: payload,
  //         [payload + "Exp"]: 1500,
  //       };
  //       rtdb.ref("exp/" + fb.auth().currentUser.uid).update(updateProfile);
  //       state.challengeCurrentIndex = state.profileExpList.findIndex(
  //         (account) => {
  //           return account.streamer === payload;
  //         }
  //       );
  //       state.currentExpList =
  //         state.profileExpList[state.challengeCurrentIndex];
  //       state.challengeStepsCompleted = state.currentExpList.currentStep;
  //       state.challengeStepsTime = moment((1000 * 60) / 2);
  //       state.challengeTime = state.challengeStepsTime.format("mm:ss");
  //       state.challengeShowDaily = true;
  //     }
  //   }
  // },
  // SET_RTDB_ACCOUNT_EXP(state, payload) {
  //   state.accountTotalExp = 0;
  //   // state.accountExpList = payload.val().expList;
  //   state.accountExpList.forEach((item) => {
  //     state.accountTotalExp = state.accountTotalExp + item.totalExp;
  //   });
  //   state.currentProfileRanking =
  //     state.profileExp[payload.val().username + "Exp"];
  //   state.currentProfileStreak =
  //     state.profileExp[payload.val().username + "Streak"];
  // },
};

const getters = {};

const actions = {
  // async GET_ACCOUNT_EXP_RANKINGS({ commit, state }, user) {
  //   state.accountRankings = [];
  //   let getData = await rtdb.ref("/exp/").orderByChild(user).equalTo(user);
  //   getData.on("value", function (snapshot) {
  //     let snap = snapshot;
  //     snap.forEach((doc) => {
  //       if (
  //         !state.accountRankings.some(
  //           (account) => account.username === doc.val().username
  //         )
  //       ) {
  //         const data = {
  //           username: doc.val().username,
  //           [user + "Exp"]: doc.val()[user + "Exp"],
  //           [user + "Streak"]: doc.val()[user + "Streak"],
  //           userId: doc.val().userId,
  //         };
  //         commit("SET_ACCOUNT_EXP_RANKINGS", data);
  //         if (state.accountSubscription) {
  //           commit("SET_PROFILE_EXP_LIST", user);
  //         }
  //         commit("ACCOUNT_EXP_LOADED");
  //       }
  //     });
  //     if (state.accountSubscription) {
  //       commit("SET_PROFILE_EXP_LIST", user);
  //     }
  //   });
  // },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
