<template>
  <v-container
    fluid
    grid-list-xs
    :class="routeName === 'leaderboard' ? 'mt-10 ' : ' mt-0 pt-0 mx-0 px-0'"
  >
    <!-- <v-row
      class="ma-0 pa-0"
      justify="center"
      v-if="user && accountSubscription"
    >
      <v-col cols="9" :md="routeName === 'leaderboard' ? '6' : '12'">
        <v-btn
          v-if="accountSubscription"
          :to="'/' + account.username"
          class="white--text blue hidden-sm-and-up mb-2"
        >
          Back to profile
        </v-btn>
        <v-btn @click="show = !show" :disabled="!challengeShowDaily"
          ><h4>
            <span v-if="challengeShowDaily">Start</span>
            <span v-else>Completed</span> daily challenges
          </h4>
          <v-icon v-if="challengeShowDaily">{{
            show ? "mdi-chevron-up" : "mdi-chevron-down"
          }}</v-icon>
        </v-btn>
      </v-col></v-row
    > -->
    <!-- <v-expand-transition>
      <div v-show="show">
        <v-stepper
          v-model="challengeStepsCompleted"
          vertical
          height="100%"
          class="transparent"
          v-if="
            challengeShowDaily &&
            user &&
            accountSubscription &&
            accountExpLoaded
          "
        >
          <div v-for="(challenge, i) in account.challenges" :key="'A' + i">
            <v-stepper-step
              :complete="challengeStepsCompleted > challenge.step"
              :step="challenge.step"
              color="blue"
              class="transparent"
            >
              <span class="white--text">{{ challenge.title }}</span>
              <small class="white--text mt-1" v-if="i === 0"
                >Gain exp by completing the steps and advance in levels</small
              >
            </v-stepper-step>

            <v-stepper-content :step="challenge.step">
              <v-card class="mb-8 blue" height="50px"
                ><h2 class="white--text pt-2 text-center">
                  {{ challengeTime }}
                </h2></v-card
              >
              <v-btn
                color="blue"
                class="mr-2"
                @click="completeStep()"
                :disabled="!stepCompleted"
              >
                Continue
              </v-btn>

              <v-btn
                color="blue"
                @click="START_COUNTER()"
                :disabled="stepLoading"
                :href="' ' + challenge.link"
                target="_blank"
              >
                <span v-if="!stepLoading" class="white--text"> Complete</span>
                <span
                  v-if="stepLoading && challengeTime === '00:00'"
                  class="white--text"
                >
                  Completed</span
                >
                <span
                  v-if="stepLoading && challengeTime !== '00:00'"
                  class="white--text"
                >
                  Loading...</span
                >
              </v-btn>
            </v-stepper-content>
          </div>
        </v-stepper>
      </div>
    </v-expand-transition> -->
    <RankingsSearchBar
      :rankings="leaderboard"
      v-if="leaderboard.type === 'exp'"
    />
    <v-row justify="center" class="ma-0 pa-0">
      <v-col
        cols="11"
        class="px-0"
        :md="routeName === 'leaderboard' ? '6' : '12'"
      >
        <v-card class="transparent" elevation="4">
          <div class="scrollable-table" style="">
            <v-simple-table class="pa-0 ma-0 transparent" dark>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th v-if="leaderboard.drawer"><h4>Leaderboard</h4></th>

                    <!-- <th class="text-left">Streak</th> -->
                    <th v-if="leaderboard.type === 'exp' && leaderboard.drawer">
                      EXP
                    </th>
                    <th
                      v-if="leaderboard.type === 'coins' && leaderboard.drawer"
                    >
                      <h5>COINS</h5>
                    </th>
                    <th
                      v-if="
                        leaderboard.type === 'credits' && leaderboard.drawer
                      "
                    >
                      <h5>CREDITS</h5>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, i) in leaderboard.rankings"
                    :key="i"
                    v-show="i < 20"
                    class="dense-row"
                  >
                    <td v-if="leaderboard.drawer">
                      <v-btn
                        x-small
                        :to="`/${item.username}`"
                        class="dense-btn ma-0 black--text"
                        @click="closeDrawer"
                        color="#FFD700"
                        v-if="i === 0"
                      >
                        <v-chip
                          x-small
                          outlined
                          color="#FFD700"
                          class="black--text px-0 mx-0 leaderboardBtnTopPadding"
                          ><h4>{{ i + 1 }}</h4>
                          .</v-chip
                        >
                        <span v-if="item.username">{{
                          truncateUsername(item.username)
                        }}</span>
                        <span v-else>{{ item.twitterUsername }}</span></v-btn
                      >
                      <v-btn
                        x-small
                        :to="`/${item.username}`"
                        class="dense-btn ma-0 black--text"
                        @click="closeDrawer"
                        color="#C0C0C0"
                        v-if="i === 1"
                      >
                        <v-chip
                          x-small
                          outlined
                          color="#C0C0C0"
                          class="black--text px-0 mx-0 leaderboardBtnTopPadding"
                          ><h4>{{ i + 1 }}</h4>
                          .</v-chip
                        >
                        <span v-if="item.username">{{
                          truncateUsername(item.username)
                        }}</span>
                        <span v-else>{{ item.twitterUsername }}</span>
                      </v-btn>
                      <v-btn
                        x-small
                        :to="`/${item.username}`"
                        class="dense-btn ma-0 black--text"
                        @click="closeDrawer"
                        color="#A37E49"
                        v-if="i === 2"
                      >
                        <v-chip
                          x-small
                          outlined
                          color="#A37E49"
                          class="black--text px-0 mx-0 leaderboardBtnTopPadding"
                          ><h4>{{ i + 1 }}</h4>
                          .</v-chip
                        >
                        <span v-if="item.username">{{
                          truncateUsername(item.username)
                        }}</span>
                        <span v-else>{{ item.twitterUsername }}</span></v-btn
                      >
                      <v-btn
                        x-small
                        :to="`/${item.username}`"
                        @click="closeDrawer"
                        class="dense-btn ma-0"
                        outlined
                        v-if="i > 2"
                      >
                        <v-chip
                          x-small
                          outlined
                          class="white--text px-0 mx-0 leaderboardBtnTopPadding"
                          ><h4>{{ i + 1 }}</h4>
                          .</v-chip
                        >
                        <span v-if="item.username">{{
                          truncateUsername(item.username)
                        }}</span>
                        <span v-else>{{ item.twitterUsername }}</span></v-btn
                      >
                    </td>

                    <!-- <td>
                      <v-chip
                        small
                        color="black"
                        v-if="item[account.username + 'Streak']"
                        >{{ item[account.username + "Streak"] }}</v-chip
                      >
                    </td> -->
                    <td v-if="leaderboard.type === 'exp' && leaderboard.drawer">
                      {{ item.userExp }}
                    </td>
                    <td
                      v-if="leaderboard.type === 'coins' && leaderboard.drawer"
                    >
                      {{ item.coins }}
                    </td>

                    <td
                      v-if="
                        leaderboard.type === 'credits' && leaderboard.drawer
                      "
                    >
                      {{ item.credits }}
                    </td>
                  </tr>
                  <tr
                    v-if="
                      !isUsernameInRankings &&
                      leaderboard.type === 'exp' &&
                      leaderboard.drawer
                    "
                  >
                    <td class="pl-6" v-if="leaderboard.rankings.length">
                      {{ leaderboard.rankings.length + 1 }}
                    </td>
                    <td class="pl-6" v-else>0</td>

                    <td>
                      <v-btn
                        x-small
                        :to="'/' + profileUsername"
                        @click="closeDrawer"
                        class="ma-0"
                        outlined
                      >
                        <span>{{ profileUsername }}</span>
                      </v-btn>
                    </td>

                    <td>
                      <span>0</span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-card></v-col
      >
    </v-row>
  </v-container>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { rtdb } from '@/firebase';
import moment from 'moment';
import RankingsSearchBar from '@/components/searchBar/RankingsSearchBar.vue';

export default {
  name: 'LeaderboardView',
  components: { RankingsSearchBar },
  props: ['leaderboard'],
  computed: {
    ...mapState([
      'accountRankings',
      'accountExpLoaded',
      'stepCompleted',
      'user',
      'account',
      'accountSubscription',
      'challengeStepsCompleted',
      'challengeShowDaily',
      'challengeTime',
      'stepLoading',
      'profileExpList',
      'currentProfileStreak',
      'profileUserId',
      'currentExpList',
      'challengeCurrentIndex',
      'currentProfileRanking',
      'route',
      'routeName',
      'profileUsername',
      'profileTwitterUsername',
    ]),
    isUsernameInRankings() {
      // Check if leaderboard.rankings is an array and contains an item with the matching username
      return (
        Array.isArray(this.leaderboard.rankings) &&
        this.leaderboard.rankings.some(
          (item) => item.username === this.profileUsername
        )
      );
    },
  },
  data() {
    return {
      show: false,
    };
  },

  created() {
    // this.GET_ACCOUNT_RANKINGS(this.route);
  },
  methods: {
    ...mapActions(['START_COUNTER']),
    truncateUsername(username) {
      const maxLength = 14;
      return username.length > maxLength
        ? username.substring(0, maxLength) + '...'
        : username;
    },
    closeDrawer() {
      this.$emit('update:drawer', true);
    },
    completeStep() {
      let update = {};

      let today = moment();
      if (this.challengeStepsCompleted !== 3) {
        if (this.currentProfileStreak >= 2) {
          this.$store.commit('UPDATE_STEPS_COMPLETED', 150);
        } else {
          this.$store.commit('UPDATE_STEPS_COMPLETED', 100);
        }
        update = {
          totalExp: this.currentExpList.totalExp,
          cooldown: this.currentExpList.cooldown,
          currentStep: this.challengeStepsCompleted,
          streamer: this.currentExpList.streamer,
        };
      } else if (this.challengeStepsCompleted === 3) {
        let tomorrow = moment(today).add(1, 'days').format('YY/MM/DD');
        let todayFormat = moment(today).format('YY/MM/DD');
        if (this.currentProfileStreak >= 2) {
          this.$store.commit('UPDATE_PROFILE_EXP', 150);
        } else {
          this.$store.commit('UPDATE_PROFILE_EXP', 100);
        }
        update = {
          totalExp: this.currentExpList.totalExp,
          cooldown: tomorrow,
          currentStep: 1,
          streamer: this.currentExpList.streamer,
        };
        this.$store.commit('UPDATE_DAILY_STATUS');
        if (todayFormat === this.currentExpList.cooldown) {
          rtdb.ref('exp/' + this.profileUserId).update({
            [this.account.username + 'Streak']: this.currentProfileStreak,
          });
        } else {
          rtdb.ref('exp/' + this.profileUserId).update({
            [this.account.username + 'Streak']: 1,
          });
        }
      }

      this.profileExpList[this.challengeCurrentIndex] = update;
      let todayFormat = moment(today).format('YY/MM/DD');
      if (todayFormat >= this.currentExpList.cooldown) {
        rtdb.ref('exp/' + this.profileUserId).update({
          expList: this.profileExpList,
          [this.account.username + 'Exp']: this.currentProfileRanking,
        });
      }
    },
  },
};
</script>
<style>
.dense-row {
  padding: 2px !important; /* Reduce padding on table rows */
}
.leaderboardBtnTopPadding {
  margin-top: 0.8px;
}
.scrollable-table {
  max-height: 600px; /* Set a maximum height for the table */
  overflow-y: auto; /* Enable vertical scrolling when table exceeds the height */
}
.dense-btn {
  min-height: 20px !important; /* Reduce button size */
  min-width: 30px !important;
  padding: 0 4px !important; /* Reduce padding inside buttons */
}
</style>
