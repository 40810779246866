var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.routeName !== 'admin' && _vm.routeName !== 'settings')?_c('div',{staticClass:"ma-0 pa-0 mx-2 mt-5"},[_c('v-btn',{staticClass:"mb-5",attrs:{"icon":"","color":"white ","dark":""},on:{"click":function($event){_vm.drawer = !_vm.drawer}}},[_c('v-icon',[_vm._v("mdi-swap-horizontal-bold")])],1),(_vm.routeName !== 'admin')?_c('div',{ref:"drawer",staticClass:"pointer ma-0 pa-0"},[_c('v-list',{staticClass:"pt-3 drawerListPad mainBg black",class:[
        !_vm.drawer && _vm.$vuetify.breakpoint.smAndUp
          ? 'closeDrawer'
          : '' || (_vm.drawer && _vm.$vuetify.breakpoint.smAndUp)
          ? 'openDrawer'
          : '',
        !_vm.drawer && _vm.$vuetify.breakpoint.xs
          ? 'hideDrawerXs'
          : '' || (_vm.drawer && _vm.$vuetify.breakpoint.xs)
          ? 'showDrawerXs'
          : '',
      ],attrs:{"scrollable":"","height":"100vh","dense":""},on:{"click":function($event){_vm.drawer = !_vm.drawer}},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[(_vm.drawer)?_c('h4',{staticClass:"white--text ml-3 mb-1"},[_vm._v(" Recommended channels ")]):_vm._e(),_vm._l((_vm.rtdbActiveSubscriptions),function(activeSubscription,i){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(activeSubscription.subscription === true),expression:"activeSubscription.subscription === true"}],key:'b' + i,on:{"click":function($event){_vm.drawer = !_vm.drawer}}},[_c('v-list-item',{staticClass:"px-2 pt-0 mb-2",class:_vm.$vuetify.breakpoint.xs ? 'ml-5' : 'ml-0',attrs:{"to":_vm.drawer ? `/${activeSubscription.username}` : null,"link":""}},[(
              activeSubscription.profilePhoto &&
              activeSubscription.profilePhoto.length
            )?_c('v-badge',{attrs:{"bordered":"","bottom":"","color":activeSubscription.status !== 'live'
                ? 'red accent-4'
                : 'green accent-4',"dot":activeSubscription.status !== 'live',"offset-x":"20","offset-y":"10"}},[_c('v-avatar',{staticClass:"ml-1 mr-1 white",attrs:{"size":"35"}},[_c('v-img',{attrs:{"cover":"","src":activeSubscription.profilePhoto,"lazy-src":activeSubscription.profilePhoto,"alt":"alt"}})],1)],1):_vm._e(),(!activeSubscription.profilePhoto)?_c('v-badge',{attrs:{"bordered":"","bottom":"","color":activeSubscription.state !== 'online'
                ? 'red accent-4'
                : 'green accent-4',"dot":activeSubscription.state !== 'online',"offset-x":"20","offset-y":"10"}},[_c('v-avatar',{staticClass:"ml-1 mr-1 white",attrs:{"size":"35"}},[_c('h4',{staticClass:"white--text"},[_vm._v("VF")])])],1):_vm._e(),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"7"}},[(!activeSubscription.displayName)?_c('v-list-item-title',{staticClass:"d-inline-flex white--text text-uppercase"},[_vm._v(_vm._s(activeSubscription.username))]):_c('v-list-item-title',{staticClass:"d-inline-flex white--text text-uppercase"},[_vm._v(_vm._s(activeSubscription.displayName))]),_c('v-list-item-subtitle',{staticClass:"white--text"},[_vm._v(" "+_vm._s(activeSubscription.game)+" ")])],1),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"2"}},[(_vm.drawer)?_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-arrow-bottom-right-bold-box")]):_vm._e()],1)],1)],1)}),_c('v-divider',{staticClass:"mt-3 thin-divider",attrs:{"color":"white"}}),_c('v-tabs',{staticClass:"black custom-tabs",attrs:{"color":"black","dense":"","background-color":"black","slider-color":!_vm.drawer ? 'black' : 'white'},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{staticClass:"white--text custom-tab"},[_vm._v("Coins")]),_c('v-tab',{staticClass:"white--text custom-tab"},[_vm._v("Credits")]),_c('v-tabs-items',{staticClass:"black",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{staticClass:"black"},[_c('LeaderboardComponent',{attrs:{"leaderboard":{
                rankings: _vm.coinsRankings,
                type: 'coins',
                drawer: _vm.drawer,
              }},on:{"update:drawer":_vm.handleDrawerUpdate}})],1),_c('v-tab-item',{staticClass:"black"},[_c('LeaderboardComponent',{attrs:{"leaderboard":{
                rankings: _vm.creditsRankings,
                type: 'credits',
                drawer: _vm.drawer,
              }},on:{"update:drawer":_vm.handleDrawerUpdate}})],1)],1)],1)],2)],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }