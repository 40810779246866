<template>
  <div
    class="ma-0 pa-0 mx-2 mt-5"
    v-if="routeName !== 'admin' && routeName !== 'settings'"
  >
    <v-btn icon color="white " class="mb-5" dark @click="drawer = !drawer">
      <v-icon>mdi-swap-horizontal-bold</v-icon>
    </v-btn>

    <div class="pointer ma-0 pa-0" v-if="routeName !== 'admin'" ref="drawer">
      <v-list
        class="pt-3 drawerListPad mainBg black"
        scrollable
        height="100vh"
        dense
        @click="drawer = !drawer"
        v-model="drawer"
        :class="[
          !drawer && $vuetify.breakpoint.smAndUp
            ? 'closeDrawer'
            : '' || (drawer && $vuetify.breakpoint.smAndUp)
            ? 'openDrawer'
            : '',
          !drawer && $vuetify.breakpoint.xs
            ? 'hideDrawerXs'
            : '' || (drawer && $vuetify.breakpoint.xs)
            ? 'showDrawerXs'
            : '',
        ]"
      >
        <h4 v-if="drawer" class="white--text ml-3 mb-1">
          Recommended channels
        </h4>

        <!-- <v-card
          max-width="280px"
          elevation="0"
          :class="!drawer ? ['ml-0', 'pt-2'] : ['ml-2', 'pt-4']"
        >
          <v-btn color="purple" class="my-2" text v-if="!drawer && user"
            ><v-icon>mdi-magnify</v-icon></v-btn
          >
          <v-text-field
            v-if="drawer"
            v-model="search"
            solo
            color="purple"
            label="Search"
            @change="searchResult"
            append-icon="mdi-magnify"
            class="mr-1"
            v-show="user"
          ></v-text-field>
        </v-card> -->
        <div
          v-for="(activeSubscription, i) in rtdbActiveSubscriptions"
          :key="'b' + i"
          v-show="activeSubscription.subscription === true"
          @click="drawer = !drawer"
        >
          <v-list-item
            :to="drawer ? `/${activeSubscription.username}` : null"
            link
            class="px-2 pt-0 mb-2"
            :class="$vuetify.breakpoint.xs ? 'ml-5' : 'ml-0'"
          >
            <v-badge
              bordered
              bottom
              :color="
                activeSubscription.status !== 'live'
                  ? 'red accent-4'
                  : 'green accent-4'
              "
              :dot="activeSubscription.status !== 'live'"
              offset-x="20"
              offset-y="10"
              v-if="
                activeSubscription.profilePhoto &&
                activeSubscription.profilePhoto.length
              "
            >
              <v-avatar size="35" class="ml-1 mr-1 white">
                <v-img
                  cover
                  :src="activeSubscription.profilePhoto"
                  :lazy-src="activeSubscription.profilePhoto"
                  alt="alt"
                />
              </v-avatar>
            </v-badge>
            <v-badge
              bordered
              bottom
              :color="
                activeSubscription.state !== 'online'
                  ? 'red accent-4'
                  : 'green accent-4'
              "
              :dot="activeSubscription.state !== 'online'"
              offset-x="20"
              offset-y="10"
              v-if="!activeSubscription.profilePhoto"
            >
              <v-avatar size="35" class="ml-1 mr-1 white">
                <h4 class="white--text">VF</h4>
              </v-avatar>
            </v-badge>

            <v-col cols="7" class="my-0 py-0">
              <v-list-item-title
                v-if="!activeSubscription.displayName"
                class="d-inline-flex white--text text-uppercase"
                >{{ activeSubscription.username }}</v-list-item-title
              >
              <v-list-item-title
                class="d-inline-flex white--text text-uppercase"
                v-else
                >{{ activeSubscription.displayName }}</v-list-item-title
              >
              <v-list-item-subtitle class="white--text">
                {{ activeSubscription.game }}
              </v-list-item-subtitle>
            </v-col>
            <v-col cols="2" class="my-0 py-0">
              <v-icon color="white" v-if="drawer"
                >mdi-arrow-bottom-right-bold-box</v-icon
              >
            </v-col>
          </v-list-item>
        </div>
        <v-divider color="white" class="mt-3 thin-divider"></v-divider>
        <v-tabs
          v-model="tab"
          class="black custom-tabs"
          color="black"
          dense
          background-color="black"
          :slider-color="!drawer ? 'black' : 'white'"
        >
          <v-tab class="white--text custom-tab">Coins</v-tab>
          <v-tab class="white--text custom-tab">Credits</v-tab>

          <v-tabs-items v-model="tab" class="black">
            <v-tab-item class="black">
              <LeaderboardComponent
                @update:drawer="handleDrawerUpdate"
                :leaderboard="{
                  rankings: coinsRankings,
                  type: 'coins',
                  drawer: drawer,
                }"
              />
            </v-tab-item>
            <v-tab-item class="black">
              <LeaderboardComponent
                @update:drawer="handleDrawerUpdate"
                :leaderboard="{
                  rankings: creditsRankings,
                  type: 'credits',
                  drawer: drawer,
                }"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>

        <!-- <div
          v-for="(item, i) in searchResult"
          :key="'B' + i"
          class="pt-1"
          v-show="search && searchResult"
        >
          <v-list-item
            link
            class="px-2 pt-0"
            v-if="item.username !== profileUsername && item.activeSubscription !== true"
          >
            <v-badge
              bordered
              bottom
              :color="
                item.state !== 'online' ? 'red accent-4' : 'green accent-4'
              "
              :dot="item.state !== 'online'"
              offset-x="20"
              offset-y="10"
              v-if="item.profilePhoto"
            >
                            <v-avatar size="35" class="ml-1 mr-1 white">
                <v-img
                  cover
                  :src="item.profilePhoto"
                  :lazy-src="item.profilePhoto"
                  alt="alt"
                />
              </v-avatar>
            </v-badge>

            <v-badge
              bordered
              bottom
              :color="
                item.state !== 'online' ? 'red accent-4' : 'green accent-4'
              "
              :dot="item.state !== 'online'"
              offset-x="20"
              offset-y="10"
              v-if="!item.profilePhoto"
            >
                            <v-avatar size="35" class="ml-1 mr-1 white"> 
                <h4 class="black--text">{{ item.profileInitials }}</h4>
              </v-avatar>
            </v-badge>

            <v-col cols="7" class="my-0 py-0">
              <v-list-item-title
                v-if="!item.displayName"
                class="d-inline-flex text-uppercase"
                >{{ item.username }}</v-list-item-title
              >
              <v-list-item-title class="d-inline-flex text-uppercase" v-else>{{
                item.displayName
              }}</v-list-item-title>
            </v-col>
            <v-col cols="2" class="my-0 py-0">
              <v-btn
                :to="`/${item.username}`"
                v-if="drawer"
                @click.stop="drawer = !drawer"
                x-small
                icon
                fab
                color="black"
                class="white mr-2"
                ><v-icon>mdi-account-arrow-right</v-icon></v-btn
              ></v-col
            >
          </v-list-item>
        </div>
        <v-row justify="center">
          <v-subheader v-if="!searchResult.length && streamerSearch.length">
            No results...
          </v-subheader>
        </v-row> -->
      </v-list>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import LeaderboardComponent from '@/views/profile/leaderboard/_LeaderboardView.vue';
export default {
  name: 'NavigationComponent',
  components: { LeaderboardComponent },
  data() {
    return {
      drawer: false,
      drawerCloserStatus: false,
      search: '',
      streamerSearch: '',
      items: ['All users', 'Referrals'],
      tab: 0,
    };
  },

  computed: {
    ...mapState([
      'accountsState',
      'routeName',
      'coinsRankings',
      'creditsRankings',
    ]),
    ...mapState('RtdbSubscriptionsStore', ['rtdbActiveSubscriptions']),
    ...mapState('UserStore', [
      'user',
      'profileUsername',
      'profileInitials',
      'profilePhoto',
    ]),

    searchResult() {
      let accounts = this.accountsState;

      if (this.search != '' && this.search) {
        accounts = accounts.filter((item) => {
          return item.username
            .toUpperCase()
            .includes(this.search.toUpperCase());
        });
      }
      return accounts;
    },
    searchStreamerResult() {
      let streamerAccounts = this.streamers;
      if (this.streamerSearch != '' && this.streamerSearch) {
        streamerAccounts = streamerAccounts.filter((item) => {
          return item.username
            .toUpperCase()
            .includes(this.streamerSearch.toUpperCase());
        });
      }
      return streamerAccounts;
    },
  },
  methods: {
    ...mapActions('AccountStore', ['GET_ACCOUNT']),

    handleDrawerUpdate(status) {
      this.drawerCloserStatus = status;
    },
    toggleDrawer() {
      if (!this.drawer && !this.drawerCloserStatus) {
        this.drawer = true;
      } else if (this.drawer && this.drawerCloserStatus) {
        this.drawer = false;
        this.drawerCloserStatus = false;
      } else {
        this.drawer = true;
      }
    },
    handleClickOutside(event) {
      if (!this.$refs.drawer.contains(event.target)) {
        this.drawer = false;
      }
    },
  },
};
</script>
<style scoped>
.thin-divider {
  height: 0.02px; /* Adjust the thickness of the divider */
  background-color: gray; /* Ensure the color is applied correctly */
}
/* Hide the navigation arrows for the tabs */
:deep(.v-slide-group__prev, .v-slide-group__next) {
  display: none;
}

/* Optional: Adjust the tabs to fit better without arrows */
:deep(.custom-tabs .v-tabs__container) {
  overflow: hidden; /* Ensure tabs do not overflow */
}
:deep(.v-badge--bordered .v-badge__badge) {
  min-width: 15px;
  height: 15px;
  border-radius: 15px;
}
.custom-btn {
  padding: 3px; /* Reduce the height of the tabs */
}
.custom-tabs {
  min-height: 25px; /* Reduce the height of the tabs */
}

.custom-tab {
  padding: 0 10px; /* Reduce horizontal padding */
  font-size: 12px; /* Make the font smaller */
  min-height: 25px; /* Reduce tab height */
  line-height: 25px; /* Align text vertically */
}
.closeDrawer {
  position: fixed;
  top: 64px;
  z-index: -10;
  left: 0px;
  width: 62px;
}
.hideDrawerXs {
  position: fixed;
  top: 64px;
  z-index: -10;
  left: -300px;
}

.openDrawer {
  position: fixed;
  top: 64px;
  width: 265px;
  left: 0px;
}
.showDrawerXs {
  position: fixed;
  top: 56px;
  width: 370px;
  left: 0;
}
.closeBtn {
  position: fixed;
  top: 84px;
  left: 64px !important;
  z-index: 10;
}
.openBtn {
  position: fixed;
  top: 82px !important;
  left: 285px !important;
  z-index: 10;
}
.hideBtnXs {
  position: fixed;
  top: 65px;
  left: 5px;
}
.showBtnXs {
  position: fixed;
  top: 84px;
  left: 330px;
  color: black;
  z-index: 12;
}
.v-list {
  height: 100vh; /* or any height you want */
  overflow-y: auto;
}
:deep(.v-text-field__details) {
  display: none;
}
:deep(.v-messages.theme--light) {
  display: none;
}
.drawerListPad {
  padding-bottom: 70px;
}
</style>
